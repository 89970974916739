import React, { useState, useEffect } from "react";
import "./Stock.css";
import StockAvailability from "./StockAvailability"; // Import the Stock Availability component
import SellStock from "./SellStock"; 
import StockSales from "./StockSales";
import StockPurchase from "./StockPurchase";

const Stock = () => {
  const [selectedTab, setSelectedTab] = useState("addStock");

  const [datePurchased, setDatePurchased] = useState("");


  const [suppliers, setSuppliers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);

  const [supplier, setSupplier] = useState("");
  const [isOtherSupplier, setIsOtherSupplier] = useState(false);

  const [brand, setBrand] = useState("");
  const [isOtherBrand, setIsOtherBrand] = useState(false);

  const [type, setType] = useState("");
  const [isOtherType, setIsOtherType] = useState(false);
  const [isFilterTypeText, setIsFilterTypeText] = useState(false); // 🔹 Flag for Filter Type Input

  const [stockType, setStockType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const suppliersRes = await fetch("https://almamzarcarwash.com:3001/api/stock/suppliers");
        const suppliersData = await suppliersRes.json();
        setSuppliers(suppliersData.data);
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    fetchData();
  }, []);

  // ✅ Fetch Brands and Types when Stock Type is selected
  useEffect(() => {
    if (!stockType) return;

    const fetchStockOptions = async () => {
      try {
        const response = await fetch(`https://almamzarcarwash.com:3001/api/stock/options?stockType=${stockType}`);
        const data = await response.json();

        setBrands(data.brands || []);
        setTypes(data.types || []);

        // 🔹 If "Filter" is selected, turn Type dropdown into a text input
        setIsFilterTypeText(stockType === "Filter");
      } catch (error) {
        console.error("Error fetching stock options:", error);
      }
    };

    fetchStockOptions();
  }, [stockType]);

  // ✅ Handle Supplier Change (Dropdown → Text Input)
  const handleSupplierChange = (e) => {
    const value = e.target.value;
    if (value === "Other") {
      setIsOtherSupplier(true);
      setSupplier(""); // Reset input field
    } else {
      setIsOtherSupplier(false);
      setSupplier(value);
    }
  };

  // ✅ Handle Brand Change (Dropdown → Text Input)
  const handleBrandChange = (e) => {
    const value = e.target.value;
    if (value === "Other") {
      setIsOtherBrand(true);
      setBrand(""); // Reset input field
    } else {
      setIsOtherBrand(false);
      setBrand(value);
    }
  };

  const handleSubmit = () => {
    const stockData = {
      supplier,
      brand,
      type,
      stockType,
      quantity,
      price,
      description,
      datePurchased, 
    };

    console.log("Submitting Stock:", stockData);

    fetch("https://almamzarcarwash.com:3001/api/stock/add", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(stockData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert("Stock added successfully!");
          setSupplier("");
          setBrand("");
          setType("");
          setStockType("");
          setQuantity("");
          setPrice("");
          setDescription("");
        } else {
          alert("Failed to add stock.");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="stock-management-container">
      {/* Radio buttons for switching tabs */}
      <div className="stock-toggle">
        <label>
          <input type="radio" value="addStock" checked={selectedTab === "addStock"} onChange={() => setSelectedTab("addStock")} />
          Add Stock
        </label>
        <label>
          <input type="radio" value="stockAvailability" checked={selectedTab === "stockAvailability"} onChange={() => setSelectedTab("stockAvailability")} />
          Available Stock
        </label>
        <label>
          <input type="radio" value="sellStock" checked={selectedTab === "sellStock"} onChange={() => setSelectedTab("sellStock")} />
          Sell Stock
        </label>
        <label>
          <input type="radio" value="stockSales" checked={selectedTab === "stockSales"} onChange={() => setSelectedTab("stockSales")} />
          Stock Sales
        </label> {/* ✅ New Radio Button for Stock Sales */}
        <label> {/* ✅ New Radio Button for "Stock Transactions" */}
        <input type="radio" value="stockTransactions" checked={selectedTab === "stockTransactions"} onChange={() => setSelectedTab("stockTransactions")} />
        Stock Purchase History
      </label>
      </div>

      {/* Conditional Rendering */}
      {selectedTab === "addStock" ? (
        <div className="service-transaction-container">
          
          <div className="form-grid">
           
          <div className="form-group">
  <label>Purchase Date:</label>
  <input
    type="date"
    value={datePurchased}
    onChange={(e) => setDatePurchased(e.target.value)}
  />
</div>

            <div className="form-group">
              <label>Supplier:</label>
              {!isOtherSupplier ? (
                <select value={supplier} onChange={handleSupplierChange}>
                  <option value="">Select Supplier</option>
                  {suppliers.map((s, index) => (
                    <option key={index} value={s.SupplierName}>{s.SupplierName}</option>
                  ))}
                  <option value="Other">Other</option>
                </select>
              ) : (
                <input type="text" placeholder="Enter new supplier" value={supplier} onChange={(e) => setSupplier(e.target.value)} />
              )}
            </div>
            <div className="form-group">
              <label>Stock Category:</label>
              <select value={stockType} onChange={(e) => setStockType(e.target.value)}>
                <option value="">Select Type</option>
                <option value="Oil">Oil</option>
                <option value="Filter">Filter</option>
              </select>
            </div>

            <div className="form-group">
              <label>Brand:</label>
              {!isOtherBrand ? (
                <select value={brand} onChange={handleBrandChange}>
                  <option value="">Select Brand</option>
                  {brands.map((b, index) => (
                    <option key={index} value={b}>{b}</option>
                  ))}
                  <option value="Other">Other</option>
                </select>
              ) : (
                <input type="text" placeholder="Enter new brand" value={brand} onChange={(e) => setBrand(e.target.value)} />
              )}
            </div>

          <div className="form-group">
          <label>Type:</label>
          {!isFilterTypeText ? (
            <>
              <select value={type} onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue === "Other") {
                  setIsOtherType(true);
                  setType(""); // Reset input field
                } else {
                  setIsOtherType(false);
                  setType(selectedValue);
                }
              }}>
                <option value="">Select Type</option>
                {types.map((t, index) => (
                  <option key={index} value={t}>{t}</option>
                ))}
                <option value="Other">Other</option> {/* ✅ Add "Other" Option */}
              </select>
        
              {/* 🔹 Show Input Field When "Other" is Selected */}
              {isOtherType && (
                <input 
                  type="text" 
                  placeholder="Enter Type" 
                  value={type} 
                  onChange={(e) => setType(e.target.value)} 
                />
              )}
            </>
          ) : (
            <input 
              type="text" 
              placeholder="Enter Filter Number" 
              value={type} 
              onChange={(e) => setType(e.target.value)} 
            />
          )}
        </div>
        

            <div className="form-group">
              <label>Quantity:</label>
              <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
            </div>

            <div className="form-group">
              <label>Total Price:</label>
              <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} />
            </div>

            <div className="form-group">
              <label>Description:</label>
              <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
          </div>

          <div className="button-container">
            <button onClick={handleSubmit} className="add-stock-btn">
              Add Stock
            </button>
          </div>
        </div>
        ) : selectedTab === "stockAvailability" ? (
          <StockAvailability />
        ) : selectedTab === "sellStock" ? (
          <SellStock />
        ) : selectedTab === "stockSales" ? (
          <StockSales />
        ) : (
          <StockPurchase /> /* ✅ Added "Stock Transactions" to Conditional Rendering */
        )}
      </div>
    );
  };
  
  export default Stock;