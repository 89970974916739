import React, { useState, useEffect } from "react";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Dashboard.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  // Time filter for line chart
  const [timeFilter, setTimeFilter] = useState("last7Days");
  const [chartData, setChartData] = useState(null);

  // Overall Summary items
  const [overallSummary, setOverallSummary] = useState({
    totalBalance: 0,
    totalSales: 0,
    totalExpenses: 0,
    totalLiabilities: 0,
    pendingReceivables: 0,
    pendingPayables: 0,
  });

  // Daily Summary items
  const [dailySummary, setDailySummary] = useState({
    totalSalesToday: 0,
    creditSalesToday: 0,
    oilFilterToday: 0,
    totalExpensesToday: 0,
    cashReceivedToday: 0,
  });

  // Bar chart: revenue vs expenses
  const [revenueVsExpensesData, setRevenueVsExpensesData] = useState(null);

  // Pie chart: top companies
  const [topCompaniesChartData, setTopCompaniesChartData] = useState(null);

  // Pie chart: top services (dummy or real if you have an endpoint)
  const [topServicesChartData, setTopServicesChartData] = useState(null);

  // Pie chart: payment method (dummy or real if you have an endpoint)
  const [paymentMethodChartData, setPaymentMethodChartData] = useState(null);

  // ----------------------------------------------------------------
  // 1) FETCH LINE CHART DATA
  // ----------------------------------------------------------------
  const fetchChartData = async (period) => {
    try {
      const response = await fetch(
        `https://almamzarcarwash.com:3001/api/financial/line-chart?period=${period}`
      );
      const data = await response.json();

      const formattedData = {
        labels: data.labels || [],
        datasets: [
          {
            label: "Total Sales",
            data: data.totalSales || [],
            borderColor: "#36A2EB",
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            fill: true,
            tension: 0.4,
          },
          {
            label: "Credit Sales",
            data: data.creditSales || [],
            borderColor: "#FF6384",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            fill: true,
            tension: 0.4,
          },
          {
            label: "Expenses",
            data: data.expenses || [],
            borderColor: "#FFCE56",
            backgroundColor: "rgba(255, 206, 86, 0.5)",
            fill: true,
            tension: 0.4,
          },
          {
            label: "Balance",
            data: data.balance || [],
            borderColor: "#4BC0C0",
            backgroundColor: "rgba(75, 192, 192, 0.5)",
            fill: true,
            tension: 0.4,
          },
        ],
      };

      setChartData(formattedData);
    } catch (error) {
      console.error("Error fetching chart data:", error);
      setChartData(null);
    }
  };

  // ----------------------------------------------------------------
  // 2) FETCH OVERALL SUMMARY
  // ----------------------------------------------------------------
  const fetchOverallSummary = async () => {
    try {
      const balancesRes = await fetch(
        "https://almamzarcarwash.com:3001/api/financial/current-balances"
      );
      const balancesData = await balancesRes.json();

      const revExpRes = await fetch(
        "https://almamzarcarwash.com:3001/api/financial/revenue-expenses?period=lastYear"
      );
      const revExpData = await revExpRes.json();

      const totalBalance =
        (revExpData.revenue || 0) - (revExpData.expenses || 0);

      setOverallSummary({
        totalBalance,
        totalSales: revExpData.revenue || 0,
        totalExpenses: revExpData.expenses || 0,
        totalLiabilities: balancesData.payables || 0,
        pendingReceivables: balancesData.receivables || 0,
        pendingPayables: balancesData.payables || 0,
      });
    } catch (error) {
      console.error("Error fetching overall summary:", error);
    }
  };

  // ----------------------------------------------------------------
  // 3) FETCH DAILY SUMMARY
  // ----------------------------------------------------------------
  const fetchDailySummary = async () => {
    try {
      const res = await fetch("https://almamzarcarwash.com:3001/api/financial/key-metrics");
      const data = await res.json();

      setDailySummary({
        totalSalesToday: data.totalSales || 0,
        creditSalesToday: data.creditSales || 0,
        oilFilterToday: data.oilFilterEarnings || 0,
        totalExpensesToday: data.totalExpenses || 0,
        cashReceivedToday: data.cashReceived || 0,
      });
    } catch (error) {
      console.error("Error fetching daily summary:", error);
    }
  };

  // ----------------------------------------------------------------
  // 4) FETCH REVENUE VS EXPENSES CHART
  // ----------------------------------------------------------------
  const fetchRevenueVsExpensesData = async (period) => {
    try {
      const res = await fetch(
        `https://almamzarcarwash.com:3001/api/financial/revenue-expenses?period=${period}`
      );
      const data = await res.json();
      setRevenueVsExpensesData({
        labels: ["Revenue", "Expenses"],
        datasets: [
          {
            label: "Revenue vs Expenses",
            data: [data.revenue || 0, data.expenses || 0],
            backgroundColor: ["#36A2EB", "#FF6384"],
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching revenue vs expenses:", error);
      setRevenueVsExpensesData(null);
    }
  };

  // ----------------------------------------------------------------
  // 5) FETCH TOP COMPANIES
  // ----------------------------------------------------------------
  const fetchTopCompanies = async () => {
    try {
      const res = await fetch("https://almamzarcarwash.com:3001/api/financial/top-companies");
      const data = await res.json();
      const labels = data.map((c) => c.company_name);
      const values = data.map((c) => c.total_revenue);

      setTopCompaniesChartData({
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching top companies:", error);
      setTopCompaniesChartData(null);
    }
  };

  // ----------------------------------------------------------------
  // 6) STILL-DUMMY CHARTS
  // ----------------------------------------------------------------
  const dummyTopServicesChartData = {
    labels: ["Oil Change", "Car Wash", "Tire Change", "Battery Replacement", "Engine Repair"],
    datasets: [
      {
        data: [3000, 2500, 2000, 1500, 1000],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
      },
    ],
  };

  const dummyPaymentMethodChartData = {
    labels: ["Cash", "Credit"],
    datasets: [
      {
        data: [6000, 4000],
        backgroundColor: ["#36A2EB", "#FF6384"],
      },
    ],
  };

  // ----------------------------------------------------------------
  // useEffect
  // ----------------------------------------------------------------
  useEffect(() => {
    fetchChartData(timeFilter);
    fetchOverallSummary();
    fetchDailySummary();
    fetchRevenueVsExpensesData(timeFilter);
    fetchTopCompanies();
    setTopServicesChartData(dummyTopServicesChartData);
    setPaymentMethodChartData(dummyPaymentMethodChartData);
  }, [timeFilter]);

  // ----------------------------------------------------------------
  // RENDER
  // ----------------------------------------------------------------
  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>

      {/* Filter Dropdown */}
      <div className="filter-container">
        <label htmlFor="time-filter">Time Filter:</label>
        <select
          id="time-filter"
          value={timeFilter}
          onChange={(e) => setTimeFilter(e.target.value)}
        >
          <option value="today">Today</option>
          <option value="last7Days">Last 7 Days</option>
          <option value="lastMonth">Last Month</option>
          <option value="last3Months">Last 3 Months</option>
          <option value="lastYear">Last Year</option>
        </select>
      </div>

      {/* Line Chart */}
      <div className="chart-container line-chart" style={{ height: "400px" }}>
        {chartData ? (
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: { position: "right" },
                title: {
                  display: true,
                  text: "Daily Sales & Expenses",
                },
              },
              scales: {
                x: { title: { display: true, text: "Date" } },
                y: {
                  beginAtZero: true,
                  title: { display: true, text: "Amount (AED)" },
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>

      {/* Overall Summary */}
      <h2 className="summary-heading">Overall Summary</h2>
      <div className="overall-summary">
        <div className="summary-item">
          <h3>Total Balance</h3>
          <p>AED {overallSummary.totalBalance}</p>
        </div>
        <div className="summary-item">
          <h3>Total Sales</h3>
          <p>AED {overallSummary.totalSales}</p>
        </div>
        <div className="summary-item">
          <h3>Total Expenses</h3>
          <p>AED {overallSummary.totalExpenses}</p>
        </div>
        <div className="summary-item">
          <h3>Total Liabilities</h3>
          <p>AED {overallSummary.totalLiabilities}</p>
        </div>
        <div className="summary-item">
          <h3>Pending Receivables</h3>
          <p>AED {overallSummary.pendingReceivables}</p>
        </div>
        <div className="summary-item">
          <h3>Pending Payables</h3>
          <p>AED {overallSummary.pendingPayables}</p>
        </div>
      </div>

      {/* Daily Summary */}
      <h2 className="summary-heading">Today's Summary</h2>
      <div className="daily-summary">
        <div className="summary-item">
          <h3>Total Sales (Today)</h3>
          <p>AED {dailySummary.totalSalesToday}</p>
        </div>
        <div className="summary-item">
          <h3>Credit Sales (Today)</h3>
          <p>AED {dailySummary.creditSalesToday}</p>
        </div>
        <div className="summary-item">
          <h3>Oil+Filter Earnings (Today)</h3>
          <p>AED {dailySummary.oilFilterToday}</p>
        </div>
        <div className="summary-item">
          <h3>Total Expenses (Today)</h3>
          <p>AED {dailySummary.totalExpensesToday}</p>
        </div>
        <div className="summary-item">
          <h3>Cash Received (Today)</h3>
          <p>AED {dailySummary.cashReceivedToday}</p>
        </div>
      </div>

      {/* Insights Title */}
      <h2 className="summary-heading">Insights</h2>

      <div className="charts-section">
        {/* Revenue vs Expenses Bar */}
        <div className="chart-container revenue-vs-expenses">
          <h3 className="chart-label">Revenue vs Expenses</h3>
          {revenueVsExpensesData ? (
            <Bar
              data={revenueVsExpensesData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: { position: "right" } },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>

        {/* Top Companies Pie */}
        <div className="chart-container top-companies-by-revenue">
          <h3 className="chart-label">Top Companies by Revenue</h3>
          {topCompaniesChartData ? (
            <Pie
              data={topCompaniesChartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: { position: "right" } },
              }}
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>

      <div className="charts-section">
        {/* Top Services/Products (dummy) */}
        <div className="chart-container top-services-products">
          <h3 className="chart-label">Top Services/Products</h3>
          {topServicesChartData ? (
            <Pie
              data={topServicesChartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: { position: "right" } },
              }}
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>

        {/* Sales by Payment Method (dummy) */}
        <div className="chart-container sales-by-payment-method">
          <h3 className="chart-label">Sales by Payment Method</h3>
          {paymentMethodChartData ? (
            <Pie
              data={paymentMethodChartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: { position: "right" } },
              }}
            />
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
