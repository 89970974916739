// Main_UI.jsx
import React, { useState } from 'react';
import ChannelListContainer from './ChannelListContainer';
import ServiceTransaction from './ServiceTransaction';
import Expenses from './Expenses';
import ChartOfAccounts from './ChartOfAccounts'; // Import the ChartOfAccounts component
import JournalEntries from './JournalEntries'; // Import the JournalEntries component
import Ledger from './Ledger'; // Import the Ledger component
import TrialBalance from './TrialBalance'; // Import the TrialBalance 
import Invoices from './invoices'; // Correct the import of your Invoices component
import Payments from './Payments'; // Import the Payments component
import Dashboard from './Dashboard';
import DailySales from './DailySales'
import Stock from './Stock';
import Reports from './Reports';

const Main_UI = () => {
  const [currentComponent, setCurrentComponent] = useState('ServiceTransaction'); // Default to 'ServiceTransaction'

  const handleNavigation = (component) => {
    console.log(`Navigating to: ${component}`); // Log the component being navigated to
    setCurrentComponent(component);
  };

  return (
    // Adjust the styles of the main container to have consistent padding
    <div style={{ display: 'flex', padding: '20px' }}>
      <ChannelListContainer onNavigate={handleNavigation} />
      <div style={{ flex: 1, maxWidth: '1200px', margin: '0 auto' }}>
      {currentComponent === 'DailySales' && <DailySales />}
      {currentComponent === 'Reports' && <Reports />}
      {currentComponent === 'Dashboard' && <Dashboard />}
        {currentComponent === 'ServiceTransaction' && <ServiceTransaction />}
        {currentComponent === 'Expenses' && <Expenses />}
        {currentComponent === 'ChartOfAccounts' && <ChartOfAccounts />}
        {currentComponent === 'JournalEntries' && <JournalEntries />}
        {currentComponent === 'GeneralLedger' && <Ledger />}
        {currentComponent === 'TrialBalance' && <TrialBalance />}
        {currentComponent === 'Payments' && <Payments />}
        {currentComponent === 'Invoices' && <Invoices />} {/* Add Invoices */}
        {currentComponent === 'Stock' && <Stock />} {/* Add Invoices */}
      </div>
    </div>
  );
};

export default Main_UI;
