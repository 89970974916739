import React, { useState, useEffect } from "react";
import "./StockPurchase.css"; // Ensure consistent styling

const StockPurchase = () => {
    const [stockTransactions, setStockTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        fetchStockTransactions();
    }, []);

    const fetchStockTransactions = async () => {
        try {
            const response = await fetch("https://almamzarcarwash.com:3001/api/stock/transactions");
            const data = await response.json();

            if (data.success) {
                setStockTransactions(data.data);
            } else {
                setError("Failed to fetch stock Purchase history.");
            }
        } catch (err) {
            console.error("Error fetching Purchase history:", err);
            setError("Error fetching stock Purchase history.");
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="error">{error}</p>;

    return (
        <div className="stock-transactions-container">
            <h1>Stock Purchase History</h1>
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Category</th> {/* ✅ New Column */}
                            <th>Brand</th>
                            <th>Oil Type / Filter No</th> {/* ✅ Renamed Column */}
                            <th>Quantity</th>
                            <th>Price (AED)</th>
                            <th>Supplier</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stockTransactions.length > 0 ? (
                            stockTransactions.map((transaction, index) => (
                                <tr key={index}>
                                    <td>{transaction.TransactionDate}</td>
                                    <td>{transaction.Category}</td>
                                    <td>{transaction.Brand}</td>
                                    <td>{transaction.OilType_FilterNo}</td>
                                    <td>{transaction.Quantity}</td>
                                    <td>{transaction.Price}</td>
                                    <td>{transaction.SupplierName || "-"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">No stock transactions found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StockPurchase;
