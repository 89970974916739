import React, { useState, useEffect } from "react";
import "./Reports.css"; // Ensure consistent styling

const Reports = () => {
    const [outstandingCompanies, setOutstandingCompanies] = useState([]);
    const [outstandingSuppliers, setOutstandingSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        fetchOutstandingBalances();
    }, []);

    const fetchOutstandingBalances = async () => {
        try {
            const response = await fetch("https://almamzarcarwash.com:3001/api/reports/outstanding");
            const data = await response.json();

            if (data.success) {
                // ✅ Sort by balance (Descending Order)
                const sortedCompanies = (data.outstandingCompanies || []).sort((a, b) => b.balance - a.balance);
                const sortedSuppliers = (data.outstandingSuppliers || []).sort((a, b) => b.balance - a.balance);

                setOutstandingCompanies(sortedCompanies);
                setOutstandingSuppliers(sortedSuppliers);
            } else {
                setError("Failed to fetch data.");
            }
        } catch (err) {
            console.error("Error fetching reports:", err);
            setError("Error fetching reports.");
        } finally {
            setLoading(false);
        }
    };

    // ✅ Calculate Total Outstanding Amount
    const totalCompanyBalance = outstandingCompanies.reduce((acc, company) => acc + company.balance, 0);
    const totalSupplierBalance = outstandingSuppliers.reduce((acc, supplier) => acc + supplier.balance, 0);

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="error">{error}</p>;

    return (
        <div className="reports-container">
            <h1>Outstanding Balances Report</h1>

            {/* 🔹 Outstanding Companies Table */}
            <div className="table-container">
                <h2>Companies Credit Report</h2>
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Outstanding Balance (AED)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {outstandingCompanies.length > 0 ? (
                            outstandingCompanies.map((company, index) => (
                                <tr key={index}>
                                    <td>{company.company_name}</td>
                                    <td className="balance-amount">{company.balance.toFixed(2)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">No outstanding payments.</td>
                            </tr>
                        )}
                        {/* 🔹 Total Outstanding Row */}
                        {outstandingCompanies.length > 0 && (
                            <tr className="total-row">
                                <td><strong>Total</strong></td>
                                <td className="total-amount"><strong>{totalCompanyBalance.toFixed(2)}</strong></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* 🔹 Outstanding Suppliers Table */}
            <div className="table-container">
                <h2>Suppliers Debit Report</h2>
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Supplier Name</th>
                            <th>Outstanding Balance (AED)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {outstandingSuppliers.length > 0 ? (
                            outstandingSuppliers.map((supplier, index) => (
                                <tr key={index}>
                                    <td>{supplier.SupplierName}</td>
                                    <td className="balance-amount">{supplier.balance.toFixed(2)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2">No pending payments.</td>
                            </tr>
                        )}
                        {/* 🔹 Total Outstanding Row */}
                        {outstandingSuppliers.length > 0 && (
                            <tr className="total-row">
                                <td><strong>Total</strong></td>
                                <td className="total-amount"><strong>{totalSupplierBalance.toFixed(2)}</strong></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Reports;
