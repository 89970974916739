import React, { useState, useEffect } from "react";
import "./StockAvailability.css";

const StockAvailability = () => {
  const [stockData, setStockData] = useState([]);
  const [stockType, setStockType] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchStockData();
  }, [stockType, search]);

  const fetchStockData = async () => {
    try {
      const response = await fetch(
        `https://almamzarcarwash.com:3001/api/stock/availability?stockType=${stockType}&search=${search}`
      );
      const data = await response.json();
      if (data.success) {
        setStockData(data.data);
      } else {
        console.error("Error fetching stock data:", data.error);
      }
    } catch (error) {
      console.error("Error fetching stock availability:", error);
    }
  };

  return (
    <div className="stock-availability-container">
      <h1>Availabile Stock</h1>

      {/* 🔹 Stock Table */}
      <table className="stock-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Brand</th>
            <th>Oil Type / Filter No</th>
            <th>Quantity</th>
            <th>Supplier</th>
            <th>Last Purchase Date</th>
          </tr>
        </thead>
        <tbody>
          {stockData.length > 0 ? (
            stockData.map((item, index) => (
              <tr key={index} className={item.Quantity < (item.Category === "Oil" ? 10 : 5) ? "low-stock" : ""}>
                <td>{item.Category}</td>
                <td>{item.Brand}</td>
                <td>{item.OilType_FilterNo}</td>
                <td>{Math.abs(item.Quantity)} {item.Category === "Oil" ? "Litres" : ""}</td>

                <td>{item.Supplier || "-"}</td>
                <td>{item.LastPurchaseDate ? new Date(item.LastPurchaseDate).toLocaleDateString("en-GB") : "-"}</td>

              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No stock available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StockAvailability;
