import React, { useState, useEffect } from 'react';
import './JournalEntries.css';

const JournalEntries = () => {
    const [entries, setEntries] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
    };

    useEffect(() => {
        const fetchJournalEntries = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://almamzarcarwash.com:3001/api/journalEntries');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log('Journal Entries API Response:', data); // Debug log
                setEntries(data || []);
                setFilteredEntries(data || []); // Initialize filtered entries
            } catch (error) {
                console.error('Error fetching journal entries:', error);
                setError('Failed to fetch journal entries.');
            } finally {
                setLoading(false);
            }
        };
        

        fetchJournalEntries();
    }, [startDate, endDate]);

    const handleDateFilter = () => {
        if (!startDate || !endDate) {
            alert("Please select both start and end dates.");
            return;
        }
    
        // Helper function to convert "DD-MMM-YYYY" to "YYYY-MM-DD"
        const convertDateToISO = (dateStr) => {
            const months = {
                Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06",
                Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12",
            };
            const [day, month, year] = dateStr.split("-");
            return `${year}-${months[month]}-${day.padStart(2, "0")}`;
        };
    
        // Convert start and end dates to JavaScript Date objects
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0); // Ensure start of the day
    
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999); // Ensure end of the day
    
        console.log(`Filtering from ${start.toISOString()} to ${end.toISOString()}`); // Debugging
    
        // Apply filtering to journal entries based on date range
        const filteredData = entries.filter((entry) => {
            const rawEntryDate = entry.Date;
            const isoEntryDate = convertDateToISO(rawEntryDate);
    
            const entryDate = new Date(isoEntryDate);
            entryDate.setHours(12, 0, 0, 0); // Normalize to midday
    
            console.log(`Checking entry date: ${entryDate.toISOString()}`); // Debugging
    
            return entryDate.getTime() >= start.getTime() && entryDate.getTime() <= end.getTime();
        });
    
        if (filteredData.length === 0) {
            console.log("No records found for the selected date range.");
            alert("No records found for the selected date range.");
        } else {
            console.log(`Filtered records count: ${filteredData.length}`);
        }
    
        setFilteredEntries(filteredData);
    };
     
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredEntries(
            entries.filter((entry) =>
                Object.values(entry).some(
                    (value) =>
                        value &&
                        value.toString().toLowerCase().includes(query)
                )
            )
        );
    };

    
    return (
        <div className="journal-entries-container">
            <h1>Journal Entries</h1>
            
            {/* Search Bar */}
            <div className="search-container">
                <span className="search-icon">&#128269;</span> {/* Unicode for magnifying glass icon */}
                <input
                    type="text"
                    placeholder="Search journal entries..."
                    value={searchQuery}
                    onChange={handleSearch}
                    className="search-bar"
                />
            </div>
            {/* Date Range Filter */}
            <div className="date-filter-container">
    <label>Start Date:</label>
    <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
    />
    
    <label>End Date:</label>
    <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
    />
    
    <button onClick={handleDateFilter}>Filter</button>
</div>


            
            <div className="table-container">
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p style={{ color: 'red' }}>{error}</p>
                ) : (
                    <table className="entries-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Account</th>
                                <th>Debit</th>
                                <th>Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredEntries.length === 0 ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: 'center' }}>No entries found.</td>
                                </tr>
                            ) : (
                                filteredEntries.map((entry, index) => (
                                    <tr key={index}>
                                        <td>{entry.Date ? new Date(entry.Date).toLocaleDateString('en-GB') : '-'}</td>
                                        <td>{entry.Description || '-'}</td>
                                        <td>{entry.Account || '-'}</td>
                                        <td>
                                            {entry.DebitAmount !== null && !isNaN(entry.DebitAmount)
                                                ? parseFloat(entry.DebitAmount).toFixed(2)
                                                : '-'}
                                        </td>
                                        <td>
                                            {entry.CreditAmount !== null && !isNaN(entry.CreditAmount)
                                                ? parseFloat(entry.CreditAmount).toFixed(2)
                                                : '-'}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default JournalEntries;
