import React, { useState, useEffect } from 'react';
import './DailySales.css';

const DailySales = () => {
    const [dailySales, setDailySales] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        if (selectedDate) {
            fetchDailySales();
        }
    }, [selectedDate]);

    const fetchDailySales = async () => {
        try {
            const response = await fetch(`https://almamzarcarwash.com:3001/api/dailySales?date=${selectedDate}`);
            const data = await response.json();
            if (data.success) {
                setDailySales(data.data);
            } else {
                console.error('Error fetching daily sales data:', data.error);
            }
        } catch (err) {
            console.error('Error fetching daily sales data:', err);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString || dateString.toLowerCase() === 'null') return '-'; // Handle missing dates
    
        let parsedDate;
        
        // Try parsing YYYY-MM-DD format
        if (/\d{4}-\d{2}-\d{2}/.test(dateString)) {
            parsedDate = new Date(dateString);
        } 
        // Try parsing DD-MM-YYYY format
        else if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
            const [day, month, year] = dateString.split('-');
            parsedDate = new Date(`${year}-${month}-${day}`);
        }
        // Try parsing DD/MM/YYYY format
        else if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
            const [day, month, year] = dateString.split('/');
            parsedDate = new Date(`${year}-${month}-${day}`);
        }
        else {
            parsedDate = new Date(dateString);
        }
    
        // Final check: If parsedDate is invalid, return fallback
        if (isNaN(parsedDate.getTime())) return 'Invalid Date';
    
        return parsedDate.toLocaleDateString('en-GB'); // Returns format: DD-MM-YYYY
    };
    

    const totals = dailySales.reduce(
        (acc, sale) => {
            acc.OilChange += parseFloat(sale.OilChange) || 0;
            acc.cashSales += parseFloat(sale.CashSales) || 0;
            acc.Recovery += parseFloat(sale.Recovery) || 0;
            acc.CreditSales += parseFloat(sale.CreditSales) || 0;
            acc.Commission -= parseFloat(sale.Commission) || 0; // Add Commission
            acc.Expense += sale.Service === null ? parseFloat(sale.Expense) || 0 : 0; 
            acc.Balance = sale.Balance || acc.Balance; // Use last row's balance
            acc.cashReceived = acc.cashSales + acc.Recovery; // ✅ Calculate Cash Received
            return acc;
        },
        { OilChange: 0, cashSales: 0, Recovery: 0, CreditSales: 0, Commission: 0, Expense: 0, Balance: 0 }
    );
    
    
    return (
        <div className="daily-sales-container">
            <h1>Daily Sales</h1>
            <div className="filter-container">
                <label htmlFor="date-filter">Select Date:</label>
                <input
                    type="date"
                    id="date-filter"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                />
            </div>
            <table className="daily-sales-table">
                <thead>
                    <tr>
                        <th>Sr</th>
                        <th>Date</th>
                        <th>Service</th>
                        <th>Vehicle No</th>
                        <th>Vehicle Type</th>
                        <th>Driver</th>
                        <th>Company</th>
                        <th>Oil Change</th>
                        <th>Cash Sales</th>
                        <th>Recovery</th>
                        <th>Credit Sales</th>
                        <th>Commission</th>
                        <th>Expense</th>
                        <th>Balance</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
    {dailySales.length > 0 ? (
        <>
            {dailySales.map((sale, index) => (
                sale.Service !== null ? (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatDate(sale.Date)}</td>
                        <td>{sale.Service}</td>
                        <td>{sale.VehicleNumber}</td>
                        <td>{sale.VehicleType || '-'}</td>
                        <td>{sale.Driver || '-'}</td>
                        <td>{sale.CompanyName || '-'}</td>
                        <td>{sale.OilChange ? sale.OilChange : '-'}</td>
                        <td>{sale.cashSales ? sale.cashSales : '-'}</td>
                        <td>{sale.Recovery ? sale.Recovery : '-'}</td>
                        <td>{sale.CreditSales ? sale.CreditSales : '-'}</td>
                        <td>{sale.Commission ? sale.Commission : '-'}</td>
                        <td>{sale.Expense ? sale.Expense : '-'}</td>
                        <td>{sale.Balance ? sale.Balance : '-'}</td>
                        <td>{sale.Remarks || '-'}</td>
                    </tr>
                ) : (
                    // Separate row for expenses
                    <tr key={`expense-${index}`} className="expense-row">
                        <td colSpan="12"><strong>Expense</strong></td>
                        <td colSpan="2"><strong>{sale.Expense}</strong></td>
                        <td>-</td>
                    </tr>
                )
            ))}
            {/* Total Row */}
            <tr className="totals-row">
                <td colSpan="7"><strong>Total</strong></td>
                <td><strong>{totals.OilChange}</strong></td>
                <td><strong>{totals.cashSales}</strong></td>
                <td><strong>{totals.Recovery}</strong></td>
                <td><strong>{totals.CreditSales}</strong></td>
                <td><strong>{totals.Commission}</strong></td>
                <td><strong>{totals.Expense}</strong></td>
                <td><strong>{totals.Balance}</strong></td>
                <td>-</td>
            </tr>
        </>
    ) : (
        <tr>
            <td colSpan="15">No data available for the selected date.</td>
        </tr>
    )}
</tbody>

            </table>
    
            {/* ✅ Summary Card Goes Here */}
            <div className="summary-card">
                <h2>Summary</h2>
                <div className="summary-content">
                    <div className="summary-row">
                        <span>Date:</span>
                        <span>{selectedDate ? formatDate(selectedDate) : '-'}</span>
                    </div>
                    <div className="summary-row">
                        <span>Vehicle Washing Sale:</span>
                        <span>{totals.cashSales}</span>
                    </div>
                    <div className="summary-row highlighted">
                        <span>Credit Sales:</span>
                        <span>{totals.CreditSales}</span>
                    </div>
                    <div className="summary-row">
                        <span>Oil + Filter Change:</span>
                        <span>{totals.OilChange}</span>
                    </div>
                    <hr />
                    <div className="summary-row total">
                        <span>Total Sale Amount:</span>
                        <span>{(totals.OilChange + totals.cashSales + totals.CreditSales)}</span>
                    </div>
                    <div className="summary-row expense">
                        <span>Less Expenditures:</span>
                        <span>{totals.Expense}</span>
                    </div>
                    <div className="summary-row expense">
                        <span>Less Commission:</span>
                        <span>{totals.Commission}</span>
                    </div>
                    <div className="summary-row expense">
                        <span>Recovery:</span>
                        <span>{totals.Recovery}</span>
                    </div>
                    <hr />
                    <div className="summary-row total-final">
                        <span>Balance:</span>
                        <span>{totals.Balance}</span>
                    </div>
                    <div className="summary-row cash-received">
                        <span>Cash Received:</span>
                        <span>{totals.cashReceived}</span>
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default DailySales;
