import React, { useState } from 'react';
import './ChannelListContainer.css';
import HamburgerIcon from '../assets/hamburger-icon.jpg'; // Replace with the path to your downloaded icon

const CompanyHeader = () => (
  <div className="channel-list__header">
    <p className="channel-list__header__text">Al Mamzar Car Wash</p>
  </div>
);

const ChannelListContent = ({ onNavigate, closeSidebar }) => {
  const [activeButton, setActiveButton] = useState('');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    onNavigate(buttonName);
    if (closeSidebar) closeSidebar();
  };

  const tabs = [
    { name: 'Dashboard', label: 'Dashboard' },
    { name: 'Reports', label: 'Reports' },
    { name: 'DailySales', label: 'Daily Sales' },
    { name: 'ServiceTransaction', label: 'Record Sales' },
    { name: 'Expenses', label: 'Expenses' },
    { name: 'JournalEntries', label: 'Journal Entries' },
    { name: 'GeneralLedger', label: 'Ledgers' },
    { name: 'Invoices', label: 'Invoices' },
    { name: 'Payments', label: 'Payments' },
    { name: 'Stock', label: 'Stock' },
  ];

  return (
    <div className="channel-list__content">
      <CompanyHeader />
      <div className="channel-list__buttons">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`channel-list__button ${activeButton === tab.name ? 'active' : ''}`}
            onClick={() => handleButtonClick(tab.name)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

const ChannelListContainer = ({ onNavigate }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <div className="channel-list-container">
      {/* Hamburger Menu */}
      <div className="hamburger-icon" onClick={toggleSidebar}>
        <img
          src={HamburgerIcon}
          alt="Toggle Menu"
          className="hamburger-image"
        />
      </div>

      {/* Sidebar */}
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <ChannelListContent onNavigate={onNavigate} closeSidebar={() => setSidebarOpen(false)} />
      </div>

      {/* Main Content */}
      <div className={`main-content ${sidebarOpen ? 'shrink' : ''}`}>
        
      </div>
    </div>
  );
};

export default ChannelListContainer;
