import React, { useState, useEffect } from "react";
import "./SellStock.css";

const SellStock = () => {
  // General Form States
  const [date, setDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [stockType, setStockType] = useState(""); // (Oil/Filter)
  const [notes, setNotes] = useState("");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");

  // Dropdown states for stock options
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  // Transaction mode: Cash vs Credit (default is Cash)
  const [transactionType, setTransactionType] = useState("Cash");

  // States for Credit mode fields
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isOtherCompany, setIsOtherCompany] = useState(false);
  const [manualCompanyName, setManualCompanyName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [creditContactNumber, setCreditContactNumber] = useState("");
  const [trnNumber, setTrnNumber] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNumber, setDriverNumber] = useState("");

  // Fetch stock options (brands & types) when stockType changes
  useEffect(() => {
    if (!stockType) return;

    fetch(`https://almamzarcarwash.com:3001/api/stock/options?stockType=${stockType}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setBrands(data.brands || []);
          setTypes(data.types || []);
          setSelectedBrand("");
          setSelectedType("");
        } else {
          console.error("Error fetching brands and types:", data.error);
        }
      })
      .catch((error) => console.error("Error fetching stock options:", error));
  }, [stockType]);

  // When Credit mode is active, fetch the list of companies
  useEffect(() => {
    if (transactionType === "Credit") {
      fetch("https://almamzarcarwash.com:3001/api/companiesinfo") // or /api/companiesinfo
        .then((res) => res.json())
        .then((data) => {
          // If your API returns a plain array, just set it directly:
          setCompanyList(data);
        })
        .catch((error) => console.error("Error fetching companies:", error));
    } else {
      setCompanyList([]); // Clear list when switching back to Cash
    }
  }, [transactionType]);
  

  // Handle company selection changes in Credit mode
  const handleCompanyChange = (e) => {
    const value = e.target.value;
    setSelectedCompany(value);

    if (value === "Others") {
      setIsOtherCompany(true);
      // Clear auto-filled fields when "Others" is chosen
      setContactPerson("");
      setCreditContactNumber("");
      setTrnNumber("");
      setAddress("");
      setDriverName("");
      setDriverNumber("");
    } else {
      setIsOtherCompany(false);

      const numericValue = parseInt(value, 10);
      const company = companyList.find((comp) => comp.id === numericValue);
      if (company) {
        setContactPerson(company.contactPerson || "");
        setCreditContactNumber(company.contactNo || "");
        setTrnNumber(company.trnNo || "");
        setAddress(company.address || "");
      }
      
    }
  };

  const handleSubmit = () => {
    // Prepare the data based on the transaction type
    const stockData = {
      date,
      transactionType,
      ...(transactionType === "Cash"
        ? {
            customerName,
            contactNumber,
            address,
          }
        : {
            companyName: isOtherCompany ? manualCompanyName : selectedCompany, // If "Others", it's the typed name; otherwise, it's the company_id
            contactPerson,
            creditContactNumber,
            trnNumber,
            address,
            driverName,
            driverNumber,
          }),
      vehicleType,
      vehicleNumber,
      paymentMethod,
      stockType,
      selectedBrand,
      selectedType,
      quantity,
      amount,
      notes,
    };

    console.log("Submitting Stock Entry:", stockData);

    fetch("https://almamzarcarwash.com:3001/api/stock/sell", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(stockData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert("Stock sold successfully!");
          // Reset all fields
          setDate("");
          setCustomerName("");
          setContactNumber("");
          setAddress("");
          setVehicleType("");
          setVehicleNumber("");
          setPaymentMethod("");
          setStockType("");
          setSelectedBrand("");
          setSelectedType("");
          setQuantity("");
          setAmount("");
          setNotes("");
          // Reset Credit mode fields
          setSelectedCompany("");
          setIsOtherCompany(false);
          setManualCompanyName("");
          setContactPerson("");
          setCreditContactNumber("");
          setTrnNumber("");
          setDriverName("");
          setDriverNumber("");
        } else {
          alert(data.error);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className="stock-management-container">
      <div className="service-transaction-container">
        {/* Radio Buttons for Cash / Credit */}
        <div className="form-group">
          <label>
            <input
              type="radio"
              value="Cash"
              checked={transactionType === "Cash"}
              onChange={(e) => setTransactionType(e.target.value)}
            />
            Cash
          </label>
          <label style={{ marginLeft: "20px" }}>
            <input
              type="radio"
              value="Credit"
              checked={transactionType === "Credit"}
              onChange={(e) => setTransactionType(e.target.value)}
            />
            Credit
          </label>
        </div>

        <div className="form-grid">
          {/* Date Picker */}
          <div className="form-group">
            <label>Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          {/* Conditionally render Customer Name for Cash vs Company Info for Credit */}
          {transactionType === "Cash" ? (
            <div className="form-group">
              <label>Customer Name:</label>
              <input
                type="text"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
          ) : (
            <>
              <div className="form-group">
                <label>Company Name:</label>
                {!isOtherCompany ? (
                <select value={selectedCompany} onChange={handleCompanyChange}>
                <option value="">Select Company</option>
                {companyList.map((company) => (
  <option key={company.id} value={company.id}>
    {company.name}
  </option>
))}

                <option value="Others">Others</option>
              </select>
              
                ) : (
                  <input
                    type="text"
                    value={manualCompanyName}
                    onChange={(e) => setManualCompanyName(e.target.value)}
                  />
                )}
              </div>
              <div className="form-group">
                <label>Contact Person:</label>
                <input
                  type="text"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Contact Number:</label>
                <input
                  type="text"
                  value={creditContactNumber}
                  onChange={(e) => setCreditContactNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>TRN No:</label>
                <input
                  type="text"
                  value={trnNumber}
                  onChange={(e) => setTrnNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Driver Name:</label>
                <input
                  type="text"
                  value={driverName}
                  onChange={(e) => setDriverName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Driver Number:</label>
                <input
                  type="text"
                  value={driverNumber}
                  onChange={(e) => setDriverNumber(e.target.value)}
                />
              </div>
            </>
          )}

          {/* For Cash mode, include Contact Number and Address */}
          {transactionType === "Cash" && (
            <>
              <div className="form-group">
                <label>Contact Number:</label>
                <input
                  type="text"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Address:</label>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </>
          )}

          {/* For Credit mode, allow editing of auto-filled Address if needed */}
          {transactionType === "Credit" && (
            <div className="form-group">
              <label>Address:</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          )}

          {/* Vehicle Type */}
          <div className="form-group">
            <label>Vehicle Type:</label>
            <input
              type="text"
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
            />
          </div>

          {/* Vehicle Number */}
          <div className="form-group">
            <label>Vehicle Number:</label>
            <input
              type="text"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
            />
          </div>

          {/* Payment Method */}
          <div className="form-group">
            <label>Payment Method:</label>
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">Select Payment Method</option>
              <option value="Cash">Cash</option>
              <option value="Card">Card</option>
            </select>
          </div>

          {/* Stock Category */}
          <div className="form-group">
            <label>Stock Category:</label>
            <select
              value={stockType}
              onChange={(e) => setStockType(e.target.value)}
            >
              <option value="">Select Type</option>
              <option value="Oil">Oil</option>
              <option value="Filter">Filter</option>
            </select>
          </div>

          {/* Brand */}
          <div className="form-group">
            <label>Brand:</label>
            <select
              value={selectedBrand}
              onChange={(e) => setSelectedBrand(e.target.value)}
              disabled={!brands.length}
            >
              <option value="">Select Brand</option>
              {brands.map((brand, index) => (
                <option key={index} value={brand}>
                  {brand}
                </option>
              ))}
            </select>
          </div>

          {/* Type */}
          <div className="form-group">
            <label>Type:</label>
            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              disabled={!types.length}
            >
              <option value="">Select Type</option>
              {types.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          {/* Quantity */}
          <div className="form-group">
            <label>Quantity:</label>
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>

          {/* Amount */}
          <div className="form-group">
            <label>Amount:</label>
            <input
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          {/* Notes */}
          <div className="form-group full-width">
            <label>Notes:</label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="button-container">
          <button onClick={handleSubmit} className="add-stock-btn">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SellStock;
