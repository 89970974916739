import React, { useEffect, useRef, useState } from "react";
import "./Ledger.css";

const Ledger = () => {
  const [ledgerData, setLedgerData] = useState([]);         // full ledger
  const [dateFilteredData, setDateFilteredData] = useState([]); // date-range filtered
  const [filteredLedgerData, setFilteredLedgerData] = useState([]); // final data after search

  const [selectedEntity, setSelectedEntity] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);
  const [entityType, setEntityType] = useState("supplier");

  // For Searching
  const [search, setSearch] = useState("");

  // For Date Filtering
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Track which row is highlighted
  const [selectedRow, setSelectedRow] = useState(-1);

  // 1) Use a ref for the table container so we can scroll it programmatically
  const containerRef = useRef(null);

  // Handle arrow key presses to move highlight up/down
  const handleKeyDown = (e) => {
    if (!filteredLedgerData.length) return;

    if (e.key === "ArrowDown") {
      e.preventDefault(); // Stop default scrolling
      setSelectedRow((prev) => Math.min(prev + 1, filteredLedgerData.length - 1));
    } else if (e.key === "ArrowUp") {
      e.preventDefault(); // Stop default scrolling
      setSelectedRow((prev) => Math.max(prev - 1, 0));
    }
  };

  // 2) Whenever selectedRow changes, scroll the container so the row is in view
  useEffect(() => {
    if (selectedRow < 0 || !containerRef.current) return;

    const rows = containerRef.current.querySelectorAll("tbody tr");
    if (rows[selectedRow]) {
      rows[selectedRow].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedRow]);

  // Fetch entity list (companies/suppliers) whenever entityType changes
  useEffect(() => {
    const fetchEntities = async () => {
      if (!entityType) return;

      try {
        const endpoint = entityType === "supplier" ? "suppliers" : "companies";
        const response = await fetch(`https://almamzarcarwash.com:3001/api/${endpoint}`);
        const data = await response.json();

        if (Array.isArray(data)) {
          setEntityOptions(data);
        } else {
          console.error("Unexpected response format:", data);
          setEntityOptions([]);
        }
      } catch (error) {
        console.error(`Error fetching ${entityType} names:`, error);
      }
    };

    fetchEntities();
    setSelectedEntity("");
    setLedgerData([]);
    setDateFilteredData([]);
    setFilteredLedgerData([]);
    setSelectedRow(-1);
  }, [entityType]);

  // Fetch ledger for the selected entity
  const fetchLedgerEntries = async () => {
    if (!selectedEntity) {
      alert("Please select an entity");
      return;
    }

    try {
      const encodedName = encodeURIComponent(selectedEntity);
      const response = await fetch(
        `https://almamzarcarwash.com:3001/api/ledgerEntries?entityType=${entityType}&entityName=${encodedName}`
      );
      const data = await response.json();
      setLedgerData(data);
      setDateFilteredData(data);
      setFilteredLedgerData(data);
      setSelectedRow(-1);
    } catch (error) {
      console.error("Error fetching ledger entries:", error);
    }
  };

  // Convert e.g. "01-Mar-2025" to "2025-03-01" if needed
  const convertDateToISO = (dateStr) => {
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const [day, month, year] = dateStr.split("-");
    if (!day || !month || !year || !months[month]) {
      // If format doesn't match, just return as-is
      return dateStr;
    }
    return `${year}-${months[month]}-${day.padStart(2, "0")}`;
  };

  // Date filter: only applied when user clicks "Filter"
  const handleDateFilter = () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    const filtered = ledgerData.filter((entry) => {
      const isoDate = convertDateToISO(entry.Date);
      const entryDate = new Date(isoDate);
      return entryDate >= start && entryDate <= end;
    });

    if (filtered.length === 0) {
      alert("No records found for the selected date range.");
    }

    setDateFilteredData(filtered);
  };

  // Search filter: applies automatically as user types
  useEffect(() => {
    let finalData = dateFilteredData;

    if (search.trim()) {
      const lowerSearch = search.toLowerCase();
      finalData = dateFilteredData.filter((entry) => {
        const desc = entry.Description?.toLowerCase() || "";
        return desc.includes(lowerSearch);
      });
    }

    setFilteredLedgerData(finalData);
  }, [search, dateFilteredData]);

  return (
    <div className="ledger-container">
      <h1>Ledger</h1>

      <div className="ledger-selector">
        {/* Entity Type Dropdown */}
        <div className="dropdown-container">
          <label htmlFor="entityTypeSelect">Select Entity Type:</label>
          <select
            id="entityTypeSelect"
            value={entityType}
            onChange={(e) => setEntityType(e.target.value)}
          >
            <option value="supplier">Supplier</option>
            <option value="company">Company</option>
            <option value="customer">Customer</option>
          </select>
        </div>

        {/* Entity Dropdown */}
        <div className="dropdown-container">
          <label htmlFor="entitySelect">Select Entity:</label>
          <select
            id="entitySelect"
            value={selectedEntity}
            onChange={(e) => setSelectedEntity(e.target.value)}
          >
            <option value="">--Select Entity--</option>
            {entityOptions.map((entity, index) => (
              <option key={entity.id || index} value={entity.name}>
                {entity.name}
              </option>
            ))}
          </select>
        </div>

        <button onClick={fetchLedgerEntries}>Generate Ledger</button>
      </div>

      {/* Show date/search filters only if we have ledger data */}
      {ledgerData.length > 0 && (
        <div className="filter-container">
          {/* Search Bar (Immediate Filter) */}
          <div className="search-container">
            <label>Search:</label>
            <input
              type="text"
              placeholder="Search description..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {/* Date Range Filter (Apply on Button Click) */}
          <div className="date-filter-container">
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button onClick={handleDateFilter} className="filter-button">
              Filter
            </button>
          </div>
        </div>
      )}

      {/* Make table container focusable & handle arrow keys */}
      <div
        className="entries-table-container"
        tabIndex="0"
        ref={containerRef}
        onKeyDown={handleKeyDown}
      >
        <table className="entries-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {filteredLedgerData.length === 0 ? (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  No entries found.
                </td>
              </tr>
            ) : (
              filteredLedgerData.map((entry, index) => {
                const description =
                  entry.Credit > 0
                    ? `Payment received from ${selectedEntity || "the entity"}`
                    : entry.Description || "-";

                return (
                  <tr
                    key={index}
                    onClick={() => setSelectedRow(index)}
                    className={index === selectedRow ? "highlighted-row" : ""}
                  >
                    <td>{new Date(entry.Date).toLocaleDateString()}</td>
                    <td>{description}</td>
                    <td>{entry.Debit ? entry.Debit : "-"}</td>
                    <td>{entry.Credit ? entry.Credit : "-"}</td>
                    <td>{entry.Balance}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Ledger;
