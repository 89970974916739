import React, { useState, useEffect } from "react";
import "./StockSales.css"; // Ensure styling matches other modules

const StockSales = () => {
  const [stockSales, setStockSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    fetchStockSales();
  }, []);

  const fetchStockSales = async () => {
    try {
      const response = await fetch("https://almamzarcarwash.com:3001/api/stock/sales");
      const data = await response.json();
      if (data.success) {
        setStockSales(data.data);
        setFilteredSales(data.data);
      } else {
        console.error("Error fetching stock sales:", data.error);
      }
    } catch (error) {
      console.error("Error fetching stock sales:", error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    filterSales(value, startDate, endDate);
  };

  const handleFilterByDate = () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    
    const filteredData = stockSales.filter((sale) => {
      const saleDate = new Date(sale.SaleDate);
      return saleDate >= start && saleDate <= end;
    });

    if (filteredData.length === 0) {
      alert("No records found for the selected date range.");
    }

    setFilteredSales(filteredData);
  };

  const filterSales = (searchValue, start, end) => {
    let result = stockSales;

    if (searchValue) {
      result = result.filter((sale) =>
        sale.VehicleNumber.toLowerCase().includes(searchValue)
      );
    }

    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      result = result.filter((sale) => {
        const saleDate = new Date(sale.SaleDate);
        return saleDate >= startDate && saleDate <= endDate;
      });
    }

    setFilteredSales(result);
  };

  return (
    <div className="stock-sales-container">
      <h1>Stock Sales</h1>

   


      {/* 🔹 Sales Table */}
      <table className="stock-sales-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Stock Type</th>
            <th>Brand</th>
            <th>Oil Type / Filter No</th>
            <th>Customer Name</th>  {/* ✅ New Column */}
            <th>Vehicle No</th>
            <th>Vehicle Type</th>
            <th>Quantity</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {filteredSales.length > 0 ? (
            filteredSales.map((sale, index) => (
              <tr key={index}>

<td>
  {sale.TransactionDate 
    ? new Date(sale.TransactionDate).toISOString().split("T")[0].split("-").reverse().join("/") 
    : "N/A"}
</td>

                <td>{sale.StockType}</td>
                <td>{sale.Brand}</td>
                <td>{sale.Type}</td>
                <td>{sale.CustomerName || "N/A"}</td>  {/* ✅ Display Customer Name */}
                <td>{sale.VehicleNumber}</td>
                <td>{sale.VehicleType}</td>
                <td>{Math.abs(sale.Quantity)} {sale.StockType === "Oil" ? "Litres" : ""}</td>
                <td>{sale.Amount}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No sales records found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StockSales;
